.ControlButtons-container {
    width: auto;
}

.tableControls {
    display: flex;
    flex-direction: row;
    
    
    width: 100%;
    height: 100%;
    color: white;
    padding: 12px;
}



.tableButtons-list {
    display: flex;
    list-style: none;
    padding: 0px;    
    gap: 40px;
    width: 100%;
    justify-content: flex-start;

}

/*  The refresh Button styling*/
.resfreshTable {
    width: 85px;
    height: 65px;
    background-color: white;
    border-radius: 10px;
    border: 0px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    background-size: 40px;
}

.resfreshTable:hover {
    background-color: rgb(29, 21, 70);
    color: white;
    outline: 2px solid white;
    
}

.resfreshTable:active {
    color: gainsboro;
}




.btn-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 12px 0 12px;

}

.users {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

}

.users .img {
    display: flex;

    width: 16%;
}

.users h3 {
    margin: 12px;
}

.btn:hover {
    background-color: #07ab90;
    border-radius: 12px;
}

.btn:active {
    background-color: #07ab90;
    border-radius: 12px;
}

