.GroupCardContentLayout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    gap: 8px;
    cursor: default;
    /* background-color: white; */
    position: relative;
}

.header-bar {

    color: #05aec8;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* Adjust z-index to position the overlay element above the child div */
    z-index: 1;
    border-radius: 12px;
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.top-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    position: relative;
    margin-bottom: 5px;
}

.middle-wrapper {
    width: 100%;
    height: 100%;

    display: flex;

    justify-content: space-between;
}



.siteDetails-layout {
    width: fit-content;
    white-space: nowrap;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.siteDetails-layout h3 {
    margin: 3px;
    font-size: var(--font-title);
    color: rgb(62, 62, 62);
}



.icons-container {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    position: absolute;
    left: 88%;
    top: 0%;
    gap: 1px;
    width: fit-content;
    /* background-color: black; */
}

.userCount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-style: normal;
    font-weight: 500;
}

.tagName {

    width: 80%;
}

.indicatorsCards-container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    align-items: flex-end;
}

.Link {
    all: unset;
    cursor: pointer;
    margin-top: 5px;
}

/* .arrowButton:hover img {
    cursor: pointer;
    transform: translateX(10px);
} */

.arrowButton {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.Link:hover .arrowButton {
    cursor: pointer;
    transform: translateX(10px);
    transition: transform 0.3s;
}

.middle-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
}

.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;
}

.bottomLeft-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-self: center;
    gap: 10px;
}

.userIcons {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
    /* Set a fixed width for the element */
    display: inline-block;
    /* or block, depending on your use case */
}

.userIcon {
    background-color: var(--Primary-Colour);
}

.outputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5px;
    row-gap: 5px
}

.inputs {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 5px;
    row-gap: 5px;
}

.bottom-wrapper_divider {
    background-color: rgb(127, 127, 127);

    height: 47px;
    width: 1px;

    margin-left: 20px;
    margin-right: 20px;
}

.Icon {
    width: 30px;
    height: 30px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.circular-checkbox {
    display: none;
  }
  
  /* Create a custom circular checkbox */
  .custom-checkbox {
    width: 30px;
    height: 30px;
    border: 2px solid #05aec8;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  /* The circle inside the custom checkbox when checked */
  .circular-checkbox:checked + .custom-checkbox::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: #05aec8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.skeleton-loader {
    background-color: #e0e0e0;
    /* border-radius: 4px; */
    position: relative;
    overflow: hidden;
    border-radius: 8px;
}

.skeleton-loader::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, #e0e0e0 0%, #f0f0f0 50%, #e0e0e0 100%);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}