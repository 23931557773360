.searchBar {
    border-radius: 8px;
    border: 0px;
    width: 100%;
    min-height: clamp(50px, 60px, 70px);
    font-size: 20px;
    padding-left: 60px;
    /* Adjust the size of the icon */
    background-image: url('../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Power Search/__Search.svg');

    background-size: 35px;
    background-repeat: no-repeat;
    background-position: 10px center; 
  }
  
  .searchBar:focus {
    outline: 1px solid rgb(196, 196, 196);
  }


/* Override the styles from the media query */
.searchBar {
  width: 100%; /* or any other value you want for smaller screens */
  max-width: 1120px;
}

  /* @media screen and (min-width: 1300px) {
    .searchBar{
      width: 1150px;
    }
  
  } */