.layout {
    display: flex;
    width: fit-content;
    height: auto;
    position: relative;
}

.inactiveMessage-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 2;
    height: 100%;

}

.inactiveMessage-container h2 {
    color: rgb(2, 2, 2);
    font-size: 20px;
    background-color: rgba(240, 248, 255, 0.295);
    filter: blur(100%);
    border-radius: 20px;
}

.pin-output {
    display: flex;
    flex-direction: row;
    width: 100%;
    width: 415px;
    height: 75px;
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: var(--Primary-Color-SiteView);
    border-radius: 12px 0 0 12px;
    user-select: none;
}

.pin-output h3 {
    display: flex;
    flex-direction: row;
    align-items: left;
    color: white;
    font-weight: 400;
    font-size: 18px;
}

.img {
    transform: scale(.7);
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100%;
}

.text-wrapper h2,
.pinName {
    margin: 0;
    text-align: left;
    align-items: flex-start;
    font-weight: 400;
    font-size: 18px;
}

.pinLable {
    display: flex;
    flex-direction: row;
    align-items: left;

}

.activated {
    /* color: rgb(100, 149, 237); */
    color: #4f719a;
}

.deactivated {
    color: #575967;
}

.Inactive {
    color: rgb(128, 128, 128);
}


.pin-activated {
    width: 20px;
    height: auto;
    background-color: rgb(79, 113, 154);
    /* background-color: rgb(100, 149, 237); */
    border-radius: 0 12px 12px 0;

}

.pin-deactivated {
    width: 20px;
    height: auto;
    background-color: #575967;
    border-radius: 0 12px 12px 0;
}

.pin-Inactive {
    width: 20px;
    height: auto;
    background-color: rgb(128, 128, 128);
    border-radius: 0 12px 12px 0;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* Adjust z-index to position the overlay element above the child div */
    z-index: 1;
    border-radius: 12px;
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}


.line {
    width: 5px;
    height: 80px;
    background-color: white;
    position: absolute;
    opacity: 0.4;
    transform: rotate(50deg);
}

.line2 {
    width: 5px;
    height: 80px;
    background-color: white;
    position: absolute;
    opacity: 0.4;
    transform: rotate(-50deg);
}


.HiddenIcon {
    filter: invert(100%);
    opacity: 0.3;
    z-index: 10;
    height: 160px;
}



/* 
.pin-output:hover {
    cursor: pointer;
    transform: scale(1.03);
} */

/* .layout:hover .pin-output,
.layout:hover .pin-activated {
    transform: scale(1.07);
}

.layout:hover .pin-output,
.layout:hover .pin-deactivated {
    transform: scale(1.07);
}

.pin-output:hover {
    width: 90%;
    border-radius: 12px 12px 12px 12px;
}

.pin-output:active {
    transform: scale(1);
    width: 100%;
    border-radius: 12px 12px 12px 12px;
} */


@media screen and (max-width: 2100px) {
    .layout {
        width: fit-content;
   
    }

    .pin-output {
        width: 374px;
        height: 75px;
    }

    .pin-output h3 {
        font-size: 16px;
    }


    .text-wrapper h2,
    .pinName {

        font-size: 18px;
    }
}