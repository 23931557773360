.table {
    width: 100%;
    /* height: fit-content; */
    height: 100%;
    /* padding-bottom: 20px; */

    display: flex;
    justify-content: left;
    align-items: baseline;
    
    border-radius: 12px;
    box-shadow: 0px 0px 3px rgba(196, 196, 196);
}

.card2 {
    min-width: 1500px;
    width: 100%;
    height: 100%;
}

.tableCardLayoutUI-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    
    /* padding: 20px 30px; */
}

.top-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: aqua; */
    /* gap: 5px; */
    padding: 12px 16px
}

.top-wrapper h1 {
    font-size: 18px;
    font-weight: 500;
}
.top-wrapper h3 {
    font-size: 14px;
    font-weight: 400;


}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-container {
    /* height: 100%; */
    max-height: 700px;
    width: 100%;
    overflow-y: scroll;
    /* padding-bottom: 25px; */
    /* background-color: red; */
    border-radius: 12px;

}

.table-container2 {
    border-spacing: 0;
    width: 100%;

    display: table;
    border-radius: 15px;
    padding: 10px 0px;


    height: 0px;
    max-height: 20px;
    font-weight: 900;

    /* position: relative; */

}

.loadingSpinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
/* Settingn the defaulft size of the table card if the screen width is greather than 1470px, if not then 100% */
/* @media screen and (min-width: 1470px) {
    .card {

        width: 1300px;
        height: 600px;
    }

} */


.dropDown-Icon {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 0;
    filter: grayscale(100%);
    /* filter: grayscale(100%); */

}


@media screen and (max-width: 800px) {

    /* .tableCardLayoutUI-wrapper {

        padding: 10px 15px;


    } */

    /* .top-wrapper h1 {
        font-size: 10px;
    } */

}

.fade-in-from-top {

    height: 220px;
    animation: fadeInFromTop 0.8s ease-out forwards;
}

.with-delay_1 {
    animation-delay: 0.2s;
}

@keyframes fadeInFromTop {
    to {
        height: 800px;
    }
}