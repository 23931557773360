.customDropdown {
  background-color: var(--Background-Primary);
  border: none;
  border-radius: 10px;
  width: 100%;
  height: fit-content;

  /* padding-left: 20px; */
  /* padding: 10px; */
  font-weight: 400;
  font-size: var(--font-title);
  cursor: pointer;

  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */

  display: flex;
  align-items: center;
  justify-content: space-between;

}

.invalid {
  background: var(--Validation-color);
}

.selectedOption {
  /* background-color: aquamarine; */
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding: 10px;
}

.selectedOption h3 {
  color: rgb(0, 0, 0);
  margin: 0px;
  font-size: var(--font-title);
  font-weight: 400;
  color: black;
}

.dropdownOptions {
  min-width: 440px;
  height: fit-content;
  /* max-height: 200px; */
  position: absolute;
  margin-top: 140px;

  z-index: 2;

  background-color: var(--Background-Secondary);
  border-radius: var(--button-border-radius);
  /* padding: 10px; */
  box-shadow: 0px 1px 5px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  align-items: center;


}


.dropdownOptions li {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  width: 100%;

  padding: 10px;
  justify-content: flex-start;
  gap: 10px;
}

.dropdownOptions li:hover {
  /* background-color: var(--Highlight-Select); */
  background-color: var(--Primary-Color);
  color: white;
}


.dropdownArrowImage {
  width: var(--Icon-Size-Small);
  height: 100%;
  transform-origin: center;
  transition: transform 1s;
  margin-right: 10px;
}

/* Define a CSS animation for the rotation */
@keyframes rotate180 {
  to {
    transform: rotate(180deg);
  }
}

/* Apply rotation when the 'rotate' class is present */
.rotate .dropdown-arrow_image {
  animation: rotate180 0.5s forwards;
}


.color {
  background-color: aqua;
}


.img {
  width: 30px;
  height: 30px;
}

.dropdownOptions li:hover img {
  filter: invert(100%);
}