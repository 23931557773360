.table {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
}

.card2 {
    /* min-width: 1500px; */
    width: 100%;
    height: 100%;
}

.tableCardLayoutUI-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    
}

.top-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    /* background-color: aqua; */
    justify-content: space-between;
}

.info-bar{
    display: flex;
    align-items: center;
    min-width: 240px;
    height: 50px;
    gap: 10px;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 8px;
    padding: 0px 10px;
}

.top-wrapper h1 {
    font-size: 18px;
    font-weight: 400;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-container {
    /* height: 100%; */
    max-height: 700px;
    width: 100%;
    overflow-y: scroll;
    /* padding-bottom: 25px; */
    /* background-color: red; */
    border-radius: 12px;

}

.table-container2 {
    border-spacing: 0;
    width: 100%;

    display: table;
    border-radius: 15px;
    padding: 10px 0px;


    height: 0px;
    max-height: 20px;
    font-weight: 900;

    /* position: relative; */

}

.loadingSpinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
/* Settingn the defaulft size of the table card if the screen width is greather than 1470px, if not then 100% */
/* @media screen and (min-width: 1470px) {
    .card {

        width: 1300px;
        height: 600px;
    }

} */


.dropDown-Icon {
    position: relative;
    width: 25px;
    height: 25px;
    margin: 0;
    filter: grayscale(100%);
    /* filter: grayscale(100%); */

}


.img {
    width: 40px;
    height: 40px;
    filter: invert(50%);
    /* margin-bottom: 5px; */
}

@media screen and (max-width: 800px) {

    /* .tableCardLayoutUI-wrapper {

        padding: 10px 15px;


    } */

    /* .top-wrapper h1 {
        font-size: 10px;
    } */

}

.fade-in-from-top {

    height: 220px;
    animation: fadeInFromTop 0.8s ease-out forwards;
}

.with-delay_1 {
    animation-delay: 0.2s;
}

@keyframes fadeInFromTop {
    to {
        height: 800px;
    }
}

.errorMessage {
    color: red;
    background-color: #fdd;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid red;
    border-radius: 5px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; 
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
}

.closeButton {
    background: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.saveButton {
    background: green; /* Change background color for the save button */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    float: right; /* Position the button to the right */
    /* Optional: Add some margin to separate it from other elements */
    margin-left: 10px;
}

.modalTitle {
    margin-top: 10;
}