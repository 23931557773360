.pin-switches {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    grid-gap: 15px;
    /* width: auto; */
    width: fit-content;
    padding-top: 18px;
    padding-left: 18px;
    padding-right: 18px;
    overflow-y: auto;
    height: auto;
    max-height: 540px;
}




 
    