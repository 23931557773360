
.verticalFlex {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.horizontalFlex {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.SaveButton {
    display: flex;
    position: absolute;
    right: 7px;
    top: 70px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid black;
    width: fit-content;

    height: 35px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;

    padding: 10px;
}


.SaveButton:hover,
.SaveButton:active {
    background-color: rgb(0, 155, 0);
    color: white;
}