.pin-switch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    gap: 5px;
    width: 210px;
    height: 130px;
    /* background-color: #404251; */
    /* background-image: linear-gradient(to right,#404251 , #374b53); */
    background-color: var(--Primary-Color-SiteView);
    /* border: 3px solid rgb(203, 203, 203); */
    color: white;
    user-select: none;

    position: relative;
    border-radius: 12px;
    border: none;
}


.top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;

    width: 100%;
    height: 33%;
    /* background-color: #ffbebe; */
}

.top h3 {
    font-size: 18px;
    font-weight: 400;
}


.top .img {
    height: 50px;
    width: 50px;
}

.middle {
    display: flex;
    align-items: flex-start;
    padding: 12px;

    width: 100%;
    height: 33%;
}

.bottom {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    padding-left: 5px;

}

.active-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--Primary-Color);
}

.bottom .img {
    height: 30px;
    width: 30px;
}

.Off {
    color: rgb(3, 160, 92);
}

.On {
    color: rgb(195, 12, 18);
}

.inactive {
    color: rgb(66, 66, 66);
}

.loading {
    color: rgb(117, 117, 117);
}

.pin-switch:hover {
    cursor: pointer;
    transform: scale(1.03);
}

.pin-switch:active {
    cursor: pointer;
    transform: scale(1);
    color: rgb(183, 183, 183);
}

/* .pin-switch .img:active{
    width: 100px;
    height: 100px;
    background-image: url("../../../../assets//appscreen_icons/default_closed_colour.png");
    z-index: 999;
} */


.pulse-img {
    filter: invert(100%);
}

.timer {
    width: 34px;
    height: 34px;
    border: 2px white solid;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: italic;
    padding-right: 2px;
    margin-bottom: 3px;
    margin-left: 3px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* Adjust z-index to position the overlay element above the child div */
    z-index: 1;
    border-radius: 12px;
    /* pointer-events: none; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
}

.line {
    width: 5px;
    height: 80px;
    background-color: white;
    position: absolute;
    opacity: 0.3;
    transform: rotate(50deg);
}

.line2 {
    width: 5px;
    height: 80px;
    background-color: white;
    position: absolute;
    opacity: 0.3;
    transform: rotate(-50deg);
}



.HiddenIcon {
    filter: invert(100%);
    opacity: 0.3;
    z-index: 10;
    height: 160px;
}

.disabled-hover {
    pointer-events: none;
    transform: none
}

.pin-switch:disabled {
    all: unset();
    background-color: var(--Primary-Color-SiteView);
    /* color: red */
    color: white;
}

.pin-switch:disabled:hover {
    all: unset();
    background-color: var(--Primary-Color-SiteView);
    /* color: red */
    color: white;
    transform: none
}

.LockIcon {
    filter: invert(100%);
    height: 30px;
    width: 30px;
}

.battery-percentage {
    margin: 0px;
    font-size:14px;
    font-style: italic;
    font-weight: 400 ;
}

.batteryIconContainer{
    display: flex;
    gap: 10px;
    padding-right: 5px;
}

@media screen and (max-width: 2100px) {
    .pin-switch {
        width: 190px;
        height: 120px;
    }

    .top h3 {
        font-size: 16px;
    }


    .bottom .img {
        height: 35px;
        width: 35px;
    }
}