.dropDownButton {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
}

.dropDownlist_container {
    position: absolute;
    top: calc(100% + 5px);
    right: -15px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 0px;
    background: #fff;
    border-radius: 12px;
    width: fit-content;
    min-width: 220px;
    z-index: 100;
  }
  

.container h3 {
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    margin-left: 15px;
}

.list-container {
    display: flex;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.container ul {
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
}

.DropdownItem-container {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;

    padding: 10px;
    cursor: pointer;
}

.DropdownItem-container:hover {}

.DropdownItem-container:active {}

.img {
    width: 20px;
    height: 20px;
    margin: 0;
    filter: grayscale(100%);
    background-color: transparent;
    /* margin-right: 10px; */
}

.DropdownItem-container:hover .img {
    filter: invert(50%);
}


.DropdownItem-container button {
    width: auto;
    /* background-color: aqua; */
    /* padding: 5px 10px; */

    cursor: pointer;
    background-color: rgba(255, 0, 0, 0);
}

.DropdownItem-container h2 {
    margin: 0px;

    font-size: 16px;
    color: grey;
    white-space: nowrap;
    cursor: pointer;
}


.DropdownItem-container:hover h2 {

    color: var(--Primary-color);
}

.DropdownItem-container:active h2 {

    color: rgb(0, 0, 0);
}


/*
.container {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    padding: 8px;
    z-index: 1000;
    right: 115px;
    
}


.container h3 {
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    margin-left: 15px;
    
}   

.list-container {
    display: flex;
    
    background-color: rgb(237, 237, 237);
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.container ul {
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
}

.DropdownItem-container {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
}

.dropDown-Icon {
    width: 20px;
    height: 20px;
    margin: 0;
    filter: grayscale(100%);
    /* filter: grayscale(100%); 

}

.img {
    width: 20px;
    height: 20px;
    margin: 0;
    filter: grayscale(100%);
    background-color: transparent;
}


.DropdownItem-container h2 {
    margin: 0px;
    padding: 5px 10px;
    font-size: 18px;
    color: grey;
    cursor: pointer;
}

.DropdownItem-container h2:hover {
    color: rgb(0, 0, 0);
    background-color: rgb(216, 218, 218);
    border-radius: 12px;
    
}

.DropdownItem-container h2:active {
    color: rgb(118, 118, 118);
    background-color: rgb(211, 211, 211);
    border-radius: 12px;
    
}
*/


.img {
    display: flex;

    width: 30px;
    height: 30px;
}