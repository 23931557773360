.DeviceManagerPage {
    width: 100%;
    height: 100%;
    /* padding: 12px; */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
}

.groupGrid-title {
    font-style: italic;
    text-decoration: underline;
    font-size: var(--font-Main-Title);

    text-decoration-skip-ink: auto; /* This property helps to avoid the underline being cut off */
    text-underline-offset: .3em;
    font-weight: 900;
    margin: 0;
}

.grid-card{
    width: 100%;
    border: 1px solid #d4d4d4;
    
    background-color: var(--Background-Primary);
    border-radius: 12px;
    padding: 12px;
    height: 100%;
}

.loadingSpinner-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.searchContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
}

.buttonContainer {
    display: flex;
    gap: 10px; /* Optional: Adds space between buttons */
}


.buttonViewType {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
}