.card{
  /* min-height: 177px; */
}
.grid-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 800px;
  /* background-color: rgb(254, 254, 254); */
  justify-content: space-between;
  border-radius: 12px;
  padding: 0px 0px 12px 0px;
}


.grid-ul {
  width: 100%;
  /* padding: 10px; */
  display: grid;

  /* column-gap:50px; */

  /* grid-template-columns: auto auto auto auto auto; */
  /* grid-template-columns: 450px 450px 450px 450px 450px; */
  /* grid-template-columns: repeat(5, auto); */
  /* grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(407px, 1fr));
  row-gap: 15px;
  /* padding-right: 20px; */
  /* padding-bottom: 20px; */
  /* overflow: auto; */
}

.grid-li {
  display: flex;
  width: fit-content;
}


/* @media screen and (max-width: 2200px) {
  .grid-ul {
  
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1700px) {
  .grid-ul {
  
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1205px) {
  .grid-ul {
  
    grid-template-columns: repeat(1, 1fr);
  }
} */


@media screen and (max-width: 873px) {


  /* .grid-ul {
    background-color: aquamarine;
    width: fit-content;
  }

  .grid-li {
    justify-content: center;
  } */

  .grid-ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }
}