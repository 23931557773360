.categoryFilter {
    width: fit-content;
    position: relative;
}

.button {
    background: white;
    border-radius: 10px;
    border: none;

    height: fit-content;
    min-height: clamp(50px, 60px, 70px);
    max-height: 70px;
    /* min-width: 280px; */
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 270px;
    font-size: 18px;
    padding: 0px 20px;
    white-space: nowrap;
    gap: 5px;
    /* transition: width 0.5s ease; */
}


.Icon {
    width: 35px;
    height: 35px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* transition: transform 0.3s; */
    transform: rotate(90deg);
}


.dropdown {
    background: white;
    border-radius: 10px;
    border: none;
    padding: 5px;

    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 5px;
    width: fit-content;
    white-space: nowrap;
    padding: 10px;
    font-size: 22px;
    gap: 3px;
    z-index: 1;
    min-width: 200px;
    height: 157px;
    overflow-y: scroll;
}

/* Webkit browsers (Chrome, Safari, etc.) */
.dropdown::-webkit-scrollbar {
    width: 9px;
    /* Set the width of the scrollbar */
}

/* Track */
.dropdown::-webkit-scrollbar-track {
    background: #d3d3d3;
    /* Background of the scrollbar track */
    border-radius: 0px 10px 10px 0px;
    /* Optional: add rounded corners to the track */
}

/* Handle */
.dropdown::-webkit-scrollbar-thumb {
    background: var(--Primary-Color); /* Color of the scrollbar thumb */
    border-radius: 10px; /* Optional: add rounded corners to the thumb */
}



.test {
    background: white;
    width: 10px;
    height: 10px;
    position: absolute;
}

.dropdown input {
    margin-right: 10px;
}

.active {
    background-color: var(--Primary-Color);
    color: white;
}


.categoryLabel {
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: grey;
    /* Optionally, you can move the color styling here as well */
}


.filterItem {
    -webkit-appearance: none;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
}



.filterItem:hover {
    border-color: var(--Primary-Color);
}


.filterItem:checked {
    border-color: var(--Primary-Color);
}

.filterItem:checked::after {
    content: '✔';
    /* Unicode checkmark */
    color: var(--Primary-Color);
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 4px;
}