.breadcrumb-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    width: fit-content;
    height: max-content;
    border: 1px solid rgb(196, 196, 196);
    border-radius: 8px;
    overflow: hidden;
    background: rgb(255, 255, 255);
    border: 1px solid white;
    box-shadow: 0px 0px 2px rgb(196, 196, 196);
    gap: 3px;
}


.breadcrumb-ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    background: rgb(255, 255, 255);
}

.button-container {
    display: flex;
    width: auto;
    padding: 0px 25px;
    height: 35px;
    background-color: #ffffff;
    border: 0px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 10px center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: rgb(103, 103, 103);
    text-decoration: none;

}

.button-container:hover {
    background-color: var(--Primary-Color);
    color: white;


}

.button-container:active,
.button-container.active {
    background-color: var(--Primary-Color);
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.breadcrumbs {
    max-width: 1200px;

}

.breadcrumbs>* {
    display: inline-block;
    margin-right: 10px;
}

.breadcrumbs .crumb::after {
    content: '>';
    margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after {
    display: none;
}

/* 
@media screen and (max-width: 500px) {
    .breadcrumb-container {
        all: unset;
        display: flex;
        gap: 5px;
        background-color: #ffffff00;
        border: 0px;
        overflow: none;
       


    }

    .button-container {
        all: unset;
        color: var(--Primary-Color);
        text-decoration: none;
    }

    .button-container:active,
    .button-container.active {
        all: unset;
        color: black;
        font-weight: 500;

    }
} */