.header-container {
    display: flex;
    height: 100%;
    width: 100vw;
    top: 0;
    justify-content: left;
    background-color: var(--Primary-Color);
    
    display: flex;
    align-items: center;
    z-index: 100;
    padding: 10px 24px;
}

.innerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

.container {
    display: flex;
    justify-content: space-between;
    width: fit-content;
}



.header {

    /* left: 3%; */
    width: 100%;
    height: auto;
    /* background-color: rgb(230, 230, 230, .5); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    /* box-shadow: 9px 0px 9px rgba(0, 0, 0, 0.25); */
    z-index: 10;
    gap: 50px;
    margin-left: 80px;

}

.header h3 {
    padding: 0;
    font-size: 16px;
}

.right {
    display: flex;
    align-items: center;
    gap: 40px;
}

.left {
    display: flex;
    align-items:start;
    gap: 40px;
    margin-left: 50px;
}



.text-wrapper {
    display: flex;
    gap: 90px;
}

.logo {
    height: 55px;
    width: 55px;
    border-radius: 5px;
}

.ResureLogo {
    height: 50px;
    width: auto;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
}

.ParfasLogo{
    height: 30px;
    width: auto;
    border-radius: 5px;
}

.hambugerIcon {
    filter: invert(80%);
    height: 50px;
    display: none;
    cursor: pointer;
    touch-action: none;
    user-select: none;
    /* Prevent text selection on tap */
    -webkit-tap-highlight-color: transparent;
}


.hambugerIcon:hover {
    filter: invert(90%);
}


.hambugerIcon:active {
    filter: invert(100%);
    transform: scale(.9);
}

.header .title {
    margin: 0;
    text-align: left;
    color: #ffffff;
    font-weight: 400;

}

.header .text {
    margin: 0;
    text-align: left;
    color: #ffffff;
    font-weight: bold;
    

}

.clickable{
    cursor: pointer;
}

.SiteStatus {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.siteStatusImg {
    width: 13px;
    height: 13px;
}


@media screen and (max-width: 1050px) {
    .bell {
        display: none;
    }

    .hambugerIcon {
        display: flex;
        height: 50px;
    }
}

@media screen and (max-width: 490px) {

    .container {

        margin-left: 10px;
    }

    .hambugerIcon {
        display: flex;
        height: 30px;
    }

    .header h3 {
        font-size: 10px;

    }

}


/* @media screen and (max-width: 900px) {


    .header section:nth-child(3) {
        display: none;
    }

    .header section:nth-child(5) {
        display: none;
    }
}

@media screen and (max-width: 430px) {
    .header-container {
       
        height: 100%;
    }

} */

@media screen and (max-width: 2100px) {
    .header-container {

        height: 70px;
    }

    .header h3 {
        font-size: 14px;
    }

}