.pin-outputs_list ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    width: auto;
    /* max-height: 500px; */
    height: fit-content;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    padding: 18px;
    
    border-radius: 12px;
}

