:root {
  --font-Main-Title: 24px;
  --font-title: 18px;
  --font-normal: 14px;
  --font-Mobile: 12px;


  --button-height: 37px;
  --button-border-radius: 8px;
  --button-hover: rgb(40, 59, 107);
  --Primary-Color: rgb(22, 138, 173);
  --Primary-Color-SiteView: #3b3c3f;
  --Primary-Color2: rgb(249, 124, 93);
  --Background-Primary: rgb(255, 255, 255);
  --Background-Secondary: rgb(255, 255, 255);
  --Highlight-Select: rgb(3, 175, 255);
  --Active-color: rgb(9, 140, 9);
  --Inactive-color: red;
  --Validation-color: #ed323286;
  --input-auto-fill: linear-gradient(to right, #ffcc00, #ff6633);
  --Icon-Size-Small: 4.6%;
  --Online-Colour: rgb(34, 202, 128);
  --Offline-Colour: rgb(255, 79, 112);

  --inactive_Pin: 40%;

  --PinLockColor: rgb(42, 64, 78);



  --font-size-Title: 35px;
  /* --font-size-normal: clamp(14px, 15px, 16px)  */
  --font-size-normal: 16px;
  --Button-font-size: 14px;
}

@media screen and (max-width: 1700px) {
  :root {
    /* --font-size-normal: 14px;  */
  }
}



  .fade-in-from-top {
    opacity: 0;
    transform: translateY(-60px);
    animation: fadeInFromTop 0.4s ease-out forwards;
  }

.fade-in-from-bottom {
  opacity: 0;
  transform: translateY(60px);
  /* Start from below */
  animation: fadeInFromBottom 0.4s ease-out forwards;
}

.with-delay_1 {
  animation-delay: 0.2s;
}

@keyframes fadeInFromTop {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@keyframes fadeInFromBottom {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


ul {
  list-style: none;
  margin: 0;
  padding: 0;
}