.LockContentLayout {
    width: 100%;
    /* min-height: 141px; */
    height: 100%;
    display: flex;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;
    align-items: center;
    justify-content: space-between;
}

.left-wrapper {
    width: fit-content;
    height: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
}


.subtitle {
    max-height: 100px;
    font-weight: 400;
    margin: 0px;
    width: fit-content;
}

.right-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
}

.pinName {
    color: var(--Primary-Color);
}



.Icons-Container {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    /* align-items: flex-end; */
    /* position: absolute; */
    gap: 5px;
}




.Icon {
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.Icon:hover{
    cursor: pointer;
    transform: translatey(-2px);

}


.RemoveLockIcon{
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.RemoveLockIcon:hover{
    cursor: pointer;
    transform: translatey(-2px);

}

.OTPCodeContainer {
    width: 500px;
    height: 250px;

}

