.button-container {
    width: fit-content;
    height: 65px;
    background-color: white;
    border-radius: 12px;
    border: 0px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 20px;
    text-align: left;
    display: flex;
    gap: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 3px rgb(196, 196, 196);;
}

.button-container:hover {
    background-color: var(--Primary-Color);
    color: white;
}

.active {
    background-color: var(--Primary-Color);
    color: white;
    border: 2px solid white;
}

.img {
    width: 40px;
    height: 100%;
}

.activeImg {
    filter: invert(100%);
}

.button-container:hover .img {
    filter: invert(100%);
}

@media screen and (max-width: 2100px) {
    .button-container {
        width: fit-content;

        height: 55px;

        font-size: 16px;
    }

    .img {
        width: 30px;
        height: 100%;
    }

}