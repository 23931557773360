.contentContainer {
    width: 100%;
    min-height: 141px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;

    min-height: 141.5px;
}

.top{
    display: flex;
    justify-content: space-between;
}

.contentContainer h3 {
    margin: 3px;
    font-size: var(--font-title);
    color: rgb(62, 62, 62);
    margin: 10px 0px 5px 0px;
}

.statusIndicator{
    width: 20px;
    height: 20px;
    background-color: rgb(231, 216, 0);
    border-radius: 50%;
}


.bottom-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;

    position: relative;
}

.arrowButton:hover img {
    cursor: pointer;
    transform: translateX(10px);

}

.Icon {
    width: 30px;
    height: 30px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
}


.contentContainer:hover .Icon {
    /* Transform the icon on hover of contentContainer */
    transform: translateX(10px);
}

.editContainer{
    
    width: 80%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editContainer h3{
    font-weight: 400;
}

.buttonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;   
}