.CamectContentLayout {
    width: 100%;
    /* min-height: 141px; */
    height: 100%;
    display: flex;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;
    align-items: center;
    justify-content: space-between;
}

.left-wrapper {
    width: fit-content;
    height: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
}


.subtitle {
    max-height: 100px;
    font-weight: 400;
    margin: 0px;
    width: fit-content;
}

.right-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
}

.text-container{
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    
}

.text-container:hover h4 {
    color: rgb(164, 164, 164);
}


.text-container:hover h4 {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.pinName {
    color: var(--Primary-Color);
    cursor: default;
}



.Icons-Container {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;

    align-items: center;
    /* position: absolute; */
    gap: 5px;
}




.Icon {
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.Icon:hover{
    cursor: pointer;
    transform: translatey(-2px);

}


.RemoveLockIcon{
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.removeCamectIcon{
    width: 20px;
    height: 20px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.RemoveLockIcon:hover{
    cursor: pointer;
    transform: translatey(-2px);
    filter: invert(100%);
}

.EditList{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: center; 
    justify-content: center;
}

.PinsList{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-self: flex-start; 
   justify-content: center;
}

.subPinText{
    font-weight: 400;
    margin: 0px;
}

.CancelButton:hover{
    background-color: rgb(227, 17, 17);
}


