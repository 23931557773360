.content {
  text-align: center;
  /* background-color: var(--Primary-Color); */
  background: linear-gradient(to bottom, #f4f4f4 50%, #ffffff 50%);
  height: 100vh;
  width: 100vW;
  display: flex;
  flex-direction: column;
  /* padding: 50px; */
  position: relative;
  align-items: center;
  justify-content: center;
}

.errorBox {
  height: 400px;
  width: 800px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  /* border-radius: 12px; */
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-left: 50px;
  
}

.logoTextContainer{
  display: flex;
  gap: 10px;
  padding: 10px;
  padding-left: 0px;
}

.logoTextContainer h2{
  margin: 0px;
}


.leftContent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  
}

.logo {
  width: 200px;
  height: 60px;
  border-radius: 12px;

}

.errorBox h1 {
  margin: 0px;
  color: rgb(0, 0, 0);

}

.errorBox {
  margin: 0px;
  color: rgb(0, 0, 0);
}

.text {
  font-size: 16px;
  text-align: left;
  margin: 0px;
  line-height: 1.5;
}

.errorContainer {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 5px;
  gap: 10px;

}
.erroContainer p {

  text-align: left;
  width: 300px;
  word-wrap: break-word; /* Old syntax, still widely supported */
  overflow-wrap: break-word; /* Newer syntax */
  margin: 0px;
}


.errorImage{
  width: 100px;
  height: 100px;
  position: absolute;
  /* right: 100px; */
  right: 100px;
  bottom: -50px;
}



