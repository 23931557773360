.table {
    height: 94.3%;
}

/* 
.list-items ul {
    width: 100%;
    height: 100%;
    background-color: rgb(208, 208, 208);
    border: 2px solid rgb(229, 229, 229);
}

.list-items ul li {
    width: 80%;
    height: auto;
    background-color: #989898
} */

.settingIconimg {
    width: 20px;
    height: 20px;
    align-self: center;
    justify-self: center;
    padding: 0px;
}

/* Drop down menu styling from cog wheel in table */
.container {
    position: absolute;
    border: 1px solid #ccc;
    background: #fff;
    padding: 8px;
    z-index: 1000;
    right: 20px
}

.container h3 {
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    margin-left: 15px;

}

.list-container {
    display: flex;

    background-color: rgb(237, 237, 237);
    border-radius: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.container ul {
    list-style: none;
    padding-left: 30px;
    margin: 0px;
    padding: 0px;
}

.Search-container {
    align-items: flex-start;
}

.table-container {

    box-shadow: 0px .4px 3px rgba(0, 0, 0, 0.521);
    background-color: white;
    width: 100%;
    height: 100%;
    justify-self: center;
    align-items: center;
    padding: 20px 0 20px 0;
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 0 12px 12px 12px;
}


/* Role column cell styling */

.roleCell_container {

    width: fit-content;
    border-radius: 12px;
    padding: 4px 5px;

}

.VerifiedCell_image {

    height: 30%;

    width: clamp(20px, 50%, 50px);
    height: 30%;
}

/* @media screen and (max-width: 2200px) {
    .table-container {
        width: fit-content
    }

} */

.inviteUserForm-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}