.StatusIndicatorCard {
    /* min-width: 90px; */
    width: fit-content;
    height: 30px;

    border-radius: var(--button-border-radius);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--font-normal);
    
    padding: 0px 10px;
    font-size: 12px;
}


.StatusIndicatorCard h3 {
    word-wrap: break-word;
    text-align: center;
}

.EyeSpyFX {
    background: rgb(100, 149, 237);
}

.RESURE {
    background: rgb(75, 0, 130);
}

.PARFAS {
    background: rgb(0, 0, 0);
}

.PORTAL_SECURITY {
    background: rgb(0, 0, 139);
}

.SOLO {
    background: rgb(178, 34, 34);
}

.KNOWIO{
    background: rgb(20, 155, 8);
}

.CPS{
    background: rgb(8, 155, 121);
}

.CSC_SECURITY{
    background: rgb(8, 150, 155);
}
.EES{
    background: rgb(94, 8, 155);
}

.defaultCompany {
    background: rgb(110, 110, 110);
}



@media screen and (max-width: 600px) {
    .StatusIndicatorCard {
        /* width: 60px; */
        /* padding: 6px; */
        font-size: 10px;
    }

}